<template lang="pug">
.loan-collateral.animated.fadeIn
  .row: .col
    b-dd(
      v-if = 'assetsOptions.length'
      variant = 'secondary'
      :text   ='$t("addAsset")'
    )
      b-dd-item(
        v-for          = 'assetType in assetsOptions'
        :key           = 'assetType.id'
        @click.prevent = 'addAsset(assetType.name)'
      ) {{ assetType.human }}
    | &nbsp;
    button.btn.btn-secondary(
      v-if           = 'mortgage'
      @click.prevent = 'showMortgageNewModal = true'
    ) {{ $t('addMortgage') }}
  .row.mt-2
    .col-sm-6(v-if = 'assetsOptions.length')
      v-loading(loader = 'loan:assets:fetch')
        template(#spinner)
          .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
        .text-center(v-if = 'assets.length === 0') {{ $t('assetsNotFound') }}
        template(v-else)
          loan-collateral-asset(
            v-for         = 'asset in assets'
            ref="components"
            :key          = 'asset.id'
            :asset        = 'asset'
            :seller       = 'seller'
            @update       = 'updateAssetList'
          )
    .col-sm-6(v-if = 'mortgage')
      v-loading(loader = 'loan:mortgages:fetch')
        template(#spinner)
          .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
        .text-center(v-if = 'mortgages.length === 0') {{ $t('mortgagesNotFound') }}
        template(v-else)
          loan-collateral-mortgage(
            v-for     = 'mortgage in mortgages'
            :key      = 'mortgage.id'
            :mortgage = 'mortgage'
          )

  loan-collateral-asset-new-modal(
    v-model       = 'showAssetNewModal'
    :asset-type   = 'modalAssetType'
    :store-module = 'storeModule'
    :seller       = 'seller'
  )
  loan-collateral-mortgage-new-modal(v-model = 'showMortgageNewModal')
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanCollateralAsset from './LoanCollateral/LoanCollateralAsset'
import LoanCollateralAssetNewModal from './LoanCollateral/LoanCollateralAssetNewModal'
import LoanCollateralMortgage from './LoanCollateral/LoanCollateralMortgage'
import LoanCollateralMortgageNewModal from './LoanCollateral/LoanCollateralMortgageNewModal'

export default {
  name: 'loan-collateral',

  components: {
    LoanCollateralAsset,
    LoanCollateralAssetNewModal,
    LoanCollateralMortgage,
    LoanCollateralMortgageNewModal
  },

  provide () {
    return {
      storeModule: this.storeModule
    }
  },

  props: {
    storeModule: {
      type: String,
      default: 'loans'
    },
    assetsState: {
      type: String,
      default: 'loanAssets'
    },
    mortgagesState: {
      type: String,
      default: 'loanMortgages'
    },
    mortgage: {
      type: Boolean,
      default: true
    },
    productState: {
      type: String,
      default: 'loanProduct'
    },
    seller: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      showAssetNewModal: false,
      showMortgageNewModal: false,
      modalAssetType: ''
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByCode']),
    ...mapState({
      assets (state) {
        return state[this.storeModule][this.assetsState]
      },
      mortgages (state) {
        return state[this.storeModule][this.mortgagesState]
      },
      product (state) {
        return state[this.storeModule][this.productState]
      }
    }),
    assetsOptions () {
      return this.product?.assetTypes?.map(assetType => this.classifierByCode('assetTypes', assetType)) || []
    }
  },

  async created () {
    await this.loadEditableClassifiers()
    this.loadPledgees()
    this.loadAssets()
    this.mortgage && this.loadMortgages()
  },

  methods: {
    ...mapActions('classifiers', [
      'loadEditableClassifiers'
    ]),
    ...mapActions({
      loadAssets (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadAssets`, payload)
      },
      loadProduct (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadProduct`, payload)
      },
      loadMortgages: 'loans/loadLoanMortgages'
    }),
    ...mapActions('pledgees', ['loadPledgees']),
    addAsset (assetType) {
      this.modalAssetType = assetType
      this.showAssetNewModal = true
    },
    updateAssetList () {
      this.$refs.components.forEach(component => {
        component.getAllErrorsMessages()
      })
    }
  }
}
</script>


<i18n>
en:
  addAsset:          "Add asset"
  addMortgage:       "Add mortgage"
  assetsNotFound:    "No assets"
  mortgagesNotFound: "No mortgages"
et:
  addAsset:          "Add asset"
  addMortgage:       "Add mortgage"
  assetsNotFound:    "No assets"
  mortgagesNotFound: "No mortgages"
ru:
  addAsset:          "Add asset"
  addMortgage:       "Add mortgage"
  assetsNotFound:    "No assets"
  mortgagesNotFound: "No mortgages"
</i18n>

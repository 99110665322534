import { CClassifier } from '@/models/CClassifier'
import { Expose } from 'class-transformer'

export class CApplicationAsset {
  @Expose()
  appraisalPartnerName: string

  @Expose()
  appraisalReport: boolean

  @Expose()
  appraisalValue: number

  @Expose()
  areaSizeTypeId: number

  @Expose()
  assetGroup: CClassifier

  @Expose()
  assetInfo: string

  @Expose()
  comment: string

  @Expose()
  assetTypeId: number

  @Expose()
  allocatedValue: number

  @Expose()
  description: string

  @Expose()
  id: number

  @Expose()
  immovableName: string

  @Expose()
  insuranceCompany: string

  @Expose()
  insuranceMandatory: boolean

  @Expose()
  insuredUntil: Date

  @Expose()
  registerNumber: string

  @Expose()
  sellerContactName: string

  @Expose()
  sellerEmail: string

  @Expose()
  sellerId: number

  @Expose()
  sellerPhone: string

  @Expose()
  value: number

  @Expose()
  valueDate: Date

  @Expose()
  vehicleBrand: string

  @Expose()
  vehicleMileage: number

  @Expose()
  vehicleModel: string

  @Expose()
  vehiclePower: number

  @Expose()
  vehiclePrice: number

  @Expose()
  vehicleProductionYear: string

  @Expose()
  vehicleRegistrationNumber: string

  @Expose()
  vehicleSerialNumber: string

  @Expose()
  vehicleVat: number

  @Expose()
  warrantyAddress: string

  @Expose()
  warrantyApartmentNumber: string

  @Expose()
  warrantyArea: number

  @Expose()
  warrantyCadastralUnitIdentifier: string

  @Expose()
  warrantyCity: string

  @Expose()
  warrantyCountryId: number

  @Expose()
  warrantyCountyId: number

  @Expose()
  warrantyHouseNumber: string

  @Expose()
  warrantyOwnerName: string

  @Expose()
  warrantyStreet: string

  @Expose()
  warrantyTypeId: number
}

<template lang="pug">
    fi-modal.loan-collateral-asset-new-modal(
      ref     = 'modal'
      v-model = 'showModal'
      size    = 'lg'
      lazy
    )
      form.form-horizontal(@submit.prevent = 'onSubmit') {{ $t("message") }}
      template(#modal-footer)
        button.btn.btn-primary(
          @click.prevent = 'onSubmit'
        ) {{ $t("common:confirm") }}
        button.btn.btn-secondary(
          @click.prevent = '$refs.modal.hide()'
        ) {{ $t("common:cancel") }}
    </template>


<script>
import FiModal from '@/components/FiModal'

export default {
  name: 'loan-collateral-asset-duplication-cadastral-unit-modal',

  components: {
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  methods: {
    onSubmit () {
      this.$emit('confirm')
      this.$refs.modal.hide()
    }
  }
}
</script>

<i18n>
en:
  message: "Assets with identical \"Cadastral unit identifier\" should have identical Asset info, Value, Immovable type, Reporting type, Valuation type, Value type, Value date, Location code, Postal code, Appraisal partner. Confirm storing non-identical values?"
et:
  message: "Assets with identical \"Cadastral unit identifier\" should have identical Asset info, Value, Immovable type, Reporting type, Valuation type, Value type, Value date, Location code, Postal code, Appraisal partner. Confirm storing non-identical values?"
ru:
  message: "Assets with identical \"Cadastral unit identifier\" should have identical Asset info, Value, Immovable type, Reporting type, Valuation type, Value type, Value date, Location code, Postal code, Appraisal partner. Confirm storing non-identical values?"
</i18n>

<template lang="pug">
fi-modal.loan-collateral-asset-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title", { assetType })'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    loan-collateral-asset-immovable-fields-edit.animated.fadeIn(
      v-if            = 'assetType === "IMMOVABLE"'
      :immovable-data = 'immovable'
      :asset-data     = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state          = '$v'
    )
    loan-collateral-asset-other-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "OTHER"'
      :other-data = 'other'
      :asset-data   = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state        = '$v'
      :seller       = 'seller'
    )
    loan-collateral-asset-product-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "PRODUCT"'
      :product-data = 'product'
      :asset-data   = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state        = '$v'
      :seller       = 'seller'
    )
    loan-collateral-asset-vehicle-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "VEHICLE"'
      :vehicle-data = 'vehicle'
      :asset-data   = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state        = '$v'
      :seller       = 'seller'
    )
    loan-collateral-asset-guarantees-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "GUARANTEES"'
      :add-data     = 'guarantees'
      :asset-data   = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state        = '$v'
      :seller       = 'seller'
    )
    loan-collateral-asset-securities-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "SECURITIES"'
      :add-data     = 'securities'
      :asset-owner    = 'assetOwnerData'
      :asset-data   = 'assetData'
      :state        = '$v'
      :seller       = 'seller'
    )
    loan-collateral-asset-trade-receivables-fields-edit.animated.fadeIn(
      v-else-if     = 'assetType === "TRADE_RECEIVABLES"'
      :add-data     = 'trade_receivables'
      :asset-data   = 'assetData'
      :asset-owner    = 'assetOwnerData'
      :state        = '$v'
      :seller       = 'seller'
    )

    loan-collateral-asset-duplication-cadastral-unit-modal(
      v-model  = 'showAssetDuplicationCadastralUnitModal'
      @confirm = 'handleClickConfirm'
    )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import { validation, validators } from '@/validation'
import FiModal from '@/components/FiModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import { asset } from '@/models'
import { parseSellerData } from '@/helpers'
import LoanCollateralAssetDuplicationCadastralUnitModal from './LoanCollateralAssetDuplicationCadastralUnitModal.vue'

const LoanCollateralAssetImmovableFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsImmovable' */'./LoanCollateralAssetImmovableFieldsEdit')
const LoanCollateralAssetOtherFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsOther' */'./LoanCollateralAssetOtherFieldsEdit')
const LoanCollateralAssetProductFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsProduct' */'./LoanCollateralAssetProductFieldsEdit')
const LoanCollateralAssetVehicleFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsVehicle' */'./LoanCollateralAssetVehicleFieldsEdit')
const LoanCollateralAssetTradeReceivablesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsTradeReceivables' */'./LoanCollateralAssetTradeReceivablesFieldsEdit')
const LoanCollateralAssetGuaranteesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsGuarantees' */'./LoanCollateralAssetGuaranteesFieldsEdit')
const LoanCollateralAssetSecuritiesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsSecurities' */'./LoanCollateralAssetSecuritiesFieldsEdit')


export default {
  name: 'loan-collateral-asset-new-modal',

  components: {
    LoanCollateralAssetImmovableFieldsEdit,
    LoanCollateralAssetProductFieldsEdit,
    LoanCollateralAssetVehicleFieldsEdit,
    LoanCollateralAssetTradeReceivablesFieldsEdit,
    LoanCollateralAssetGuaranteesFieldsEdit,
    LoanCollateralAssetSecuritiesFieldsEdit,
    LoanCollateralAssetOtherFieldsEdit,
    FiModal,
    LoanCollateralAssetDuplicationCadastralUnitModal
  },

  mixins: [validation],

  props: {
    storeModule: {
      type: String,
      default: 'loans'
    },
    assetType: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    seller: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      assetOwnerData: [],
      assetData: {
        ...asset.common
      },
      immovable: {
        ...asset.immovable
      },
      product: {
        ...asset.product
      },
      other: {
        ...asset.other
      },
      vehicle: {
        ...asset.vehicle
      },
      trade_receivables: {
        ...asset.trade_receivables
      },
      securities: {
        ...asset.securities
      },
      guarantees: {
        ...asset.guarantees
      },

      showAssetDuplicationCadastralUnitModal: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapState('settings', ['settings']),
    ...mapState('loans', ['loanAssets']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading(`${this.storeModule}:asset:save`)
    }
  },

  validations: {
    assetData: {
      value: {
        required: validators.required
      },
      valueDate: {
        required: validators.required
      }
    }
  },

  async created () {
    this.assetOwnerData = asset?.assetsOwners
  },

  methods: {
    ...mapActions({
      createAsset (dispatch, payload) {
        return dispatch(`${this.storeModule}/createAsset`, payload)
      }
    }),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))

      switch (this.assetType) {
        case 'IMMOVABLE' || 'TRADE_RECEIVABLES' || 'SECURITIES' || 'GUARANTEES':
          // this.immovable.warrantyCountryId = this.classifierByName('countries', this.settings.country.toLowerCase())?.id
          break
        case 'VEHICLE':
        case 'PRODUCT':
          this[this.assetType.toLowerCase()] = {
            ...this[this.assetType.toLowerCase()],
            ...parseSellerData(this.seller)
          }
          break
        case 'OTHER':
          this[this.assetType.toLowerCase()] = {
            ...this[this.assetType.toLowerCase()],
            ...parseSellerData(this.seller)
          }
          break
      }

      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.assetOwnerData.length) {
          this[this.assetType.toLowerCase()].assetsOwners = this.assetOwnerData
        }
        this.assetData.assetTypeId = this.classifierByName('assetTypes', this.assetType)?.id
        if (this.immovable.assetsOwners) {
          this.immovable.assetsOwners.forEach(owner => {
            delete owner.customerName
          })
        }

        // if (this.checkWarrantyCadastralUnitIdentifier()) {
        //   this.showAssetDuplicationCadastralUnitModal = true
        //   return
        // }

        try {
          await this.createAsset({ assetData: { ...this.assetData, ...this[this.assetType.toLowerCase()] } })
          this.$refs.modal.hide()
        } catch (e) {
          const strForCheck = 'Assets with identical \'Cadastral unit identifier\' should have identical Asset info, Value, Immovable type, Reporting type, Valuation type, Value type, Value date, Location code, Postal code, Appraisal partner. Contract with non-identical field value(s):'

          if (
            e?.response?.data?.message &&
            typeof e.response.data.message === 'string' &&
            e.response.data.message.includes(strForCheck)
          ) {
            this.showAssetDuplicationCadastralUnitModal = true
          }
        }
      }
    },
    checkWarrantyCadastralUnitIdentifier () {
      const asset = { ...this.assetData, ...this[this.assetType.toLowerCase()] }
      const currentWCUI = asset.warrantyCadastralUnitIdentifier

      const assetsWithSameWCUI = this.loanAssets.filter(el => !!currentWCUI && currentWCUI === el.warrantyCadastralUnitIdentifier)

      if (assetsWithSameWCUI.length) {
        const keys = [
          'appraisalPartnerId', 'assetInfo', 'assetReportingType',
          'assetTypeId', 'collateralLocationCode', 'valuationType',
          'value', 'valueDate', 'valueType', 'warrantyPostalCode'
        ]

        let counter = 0
        keys.forEach(key => {
          assetsWithSameWCUI.forEach(similarAsset => {
            if (asset[key] !== similarAsset[key]) {
              counter = counter + 1
            }
          })
        })

        return !!counter
      }

      return false
    },
    async handleClickConfirm () {
      // force update after confirmation
      await this.createAsset({ assetData: { ...this.assetData, ...this[this.assetType.toLowerCase()], force: true } })
      this.$refs.modal.hide()
    }
  }
}
</script>


<i18n>
en:
  title: "New {{assetType}} asset"
et:
  title: "New {{assetType}} asset"
ru:
  title: "New {{assetType}} asset"
</i18n>
